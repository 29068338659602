import React from 'react'

import { HeaderSecondary, HeaderTitle } from '@juullabs/react-components'
import { BusinessSignInForm } from '@viewlio/business/src/components/BusinessSignInForm'
import { StoreType } from '@viewlio/config/src/types'
import { GetStaticProps } from 'next'

import { FlashMessages } from 'components/common/FlashMessages'
import { Logo } from 'components/common/Headers/Logo'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { LayoutContentfulEntries } from 'lib/contentful/fetchLayoutContentfulEntries'
import { getCommonStaticProps } from 'utils/dataFetching/getCommonStaticProps'

type Props = {
  layoutContentfulEntries: LayoutContentfulEntries
}

const Signin: React.FC<Props> = ({ layoutContentfulEntries }) => (
  <>
    <HeaderSecondary
      startSlot={
        <Logo navigationEntry={layoutContentfulEntries.navigationEntry}/>
      }
      middleSlot={(
        <HeaderTitle>
          <TranslatedMessage id='wholesale.header.title' />
        </HeaderTitle>
      )}
      reverseMobile={true}
    />
    <FlashMessages />
    <BusinessSignInForm />
  </>
)

export const getStaticProps: GetStaticProps = async ({
  locale,
}) => getCommonStaticProps({
  deploymentConfig: null,
  locale,
  metaEntryLookup: 'wholesale/signin',
  storeType: StoreType.Wholesale,
})

export default Signin

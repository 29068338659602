import React, { useState } from 'react'

import { Button, TextInput } from '@juullabs/react-components'
import { paths } from '@viewlio/config/src/business/paths'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { signInBusinessUser } from 'lib/juulio/api'
import { useGlobalStore } from 'stores'
import { addFlashMessage, flushFlashMessages } from 'stores/uiStore'
import { getTranslatedError } from 'utils/formik/getTranslatedError'
import { getViewlioPath } from 'utils/routing/getViewlioPath'

import styles from './BusinessSignInForm.module.scss'
import { validationSchema } from './BusinessSignInForm.schema'

export const BusinessSignInForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [processing, setProcessing] = useState(false)

  const intl = useIntl()

  const { locale } = useGlobalStore()

  const handleIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault()
    setShowPassword(prev => !prev)
  }

  const createLink = (label: string, href: string) => (
    <LocalizedLink className={styles.link} href={href}>
      {label}
    </LocalizedLink>
  )

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setProcessing(true)
      flushFlashMessages()
      try {
        await signInBusinessUser(values)
        window.location.assign(getViewlioPath(locale, paths.account))
      } catch (error) {
        setProcessing(false)
        addFlashMessage({
          content: error.error || intl.formatMessage({ id: 'common.errors.default' }),
          type: 'error',
        })
      }
    },
    validationSchema,
  })

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header} >
          <p>
            <TranslatedMessage id='wholesale.signin.header'/>
          </p>
          <p>
            <TranslatedMessage id='wholesale.signin.subheader'/>
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <TextInput
            errorText={getTranslatedError(formik.getFieldMeta('email'))}
            labelText={<TranslatedMessage id='sign_in.form.placeholder_email'/>}
            name='email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={true}
            value={formik.values.email}
            wrapperClass={styles.textInput}
          />
          <TextInput
            errorText={getTranslatedError(formik.getFieldMeta('password'))}
            labelText={<TranslatedMessage id='sign_in.form.placeholder_password'/>}
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={true}
            value={formik.values.password}
            icon='eye_outlined'
            iconAriaLabel='show-password'
            onIconClick={handleIconClick}
            type={showPassword ? 'text' : 'password'}
            wrapperClass={styles.textInput}
          />
          <LocalizedLink
            href={paths.forgotPassword}
            className={styles.forgotPasswordlink}
          >
            <TranslatedMessage
              id='wholesale.signin.forgot_password'
            />
          </LocalizedLink>
          <Button
            processing={processing}
            disabled={!formik.isValid || !formik.dirty}
            className={styles.button}
          >
            <TranslatedMessage id='sign_in.form.submit_button'/>
          </Button>
        </form>
        <TranslatedMessage
          id='wholesale.signin.disclaimer'
          className={styles.disclaimer}
          values={{
            privacy_url: createLink(
              intl.formatMessage({ id: 'wholesale.signin.disclaimer_privacy_policy' }),
              paths.privacyPolicy,
            ),
            tos_url: createLink(
              intl.formatMessage({ id: 'wholesale.signin.disclaimer_terms_and_conditions' }),
              paths.termsAndConditions,
            ),
          }}
        />
      </div>
    </div>
  )
}
